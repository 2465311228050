






































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import qrCodeRender from './qr-code-render.vue'

@Component({
    components: {
      qrCodeRender
    },
    computed: {
    ...mapState({
      storeInfo: (state: any) => state.Store.storeInfo,
      storeSetting: (state: any) => state.Store.storeSetting
    }),

    ...mapGetters({
      branchAddress: 'Store/branchAddress'
    })
  }
})
export default class Receipt extends Vue {
  gwStoreId = '5e3548c2d32cb12606a34fb8'

  storeInfo!: any

  storeSetting!: any

  branchAddress!: any

  formatDateTime!: any

  @Prop() order!: any

  @Prop() isPreview!: boolean

  get webUrl (): any {
    return this.storeSetting?.webUrl || ''
  }

  sumAmount = (items: Array<any>): any => items.reduce((sum: number, d) => sum + d.amount, 0)

  priceStepPricing = (items: Array<any>): any => items.reduce((sum: number, d) => sum + d.price, 0)

  showFullPriceFormat = (number: number): any => ((number).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  calPriceBeforeVat = (price: number): number => (price * 100) / 107

  isMMEastville = (this.order.branchPos.unitId === 1117 || this.order.branchPos.unitId === '1117')

  showProductName = (text: string): any => {
    const a = text.split('-')
    if (a[0].length > 25) {
      return [`${a[0].substring(0, 24) }...`, a[1]]
    }
    return [a[0], a[a.length - 1]]
  }

  get previousPaid (): any {
    const previousData = { isCreditNote: false, sum: 0 }
    if (!this.order) return previousData
    if (this.order.payments.length > 1) {
      const beforeLastIndex = this.order.payments.length - 1
      for (let index = 0; index < beforeLastIndex; index++) {
        previousData.sum += this.order.payments[index].payAmount
      }
      return previousData
    }
    if (this.order?.payments.length === 1 && this.order?.payments[0]?.channel === 'CreditNote') {
      previousData.isCreditNote = true
      previousData.sum = this.order?.payments[0]?.payAmount
      return previousData
    }
    return previousData
  }

  get currentPaid (): any {
    return this.order.payments[this.order.payments.length - 1]
  }

  get branchCode (): string {
    return this.branchAddress?.code ?? ''
  }

  get hasBillId (): boolean {
    return this.order?.billId && this.order.billId !== '-'
  }

  get isGW () {
    return this.storeInfo?.id === this.gwStoreId
  }
}
