<template>
  <div>
    <canvas
      ref="qrcodeCanvas"></canvas>
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  props: {
    qrText: {
        require: true,
        type: String,
        default: ''
    },
    size: {
      type: Number,
      default: 200
    },
    darkColor: {
      type: String,
      default: '#000'
    },
    lightColor: {
      type: String,
      default: '#FFF'
    },
    errorCorrectionLevel: {
      type: String,
      default: 'M',
      validator: (value) => ['L', 'M', 'Q', 'H'].includes(value)
    }
  },
  data () {
    return {
    }
  },
  mounted () {
    this.generateQRCode()
  },
  methods: {
    generateQRCode () {
      const canvas = this.$refs.qrcodeCanvas
      QRCode.toCanvas(
        canvas,
        this.qrText,
        {
          width: this.size,
          color: {
            dark: this.darkColor,
            light: this.lightColor
          },
          errorCorrectionLevel: this.errorCorrectionLevel
        },
        (error) => {
          if (error) console.error(error)
        }
      )
    }
  }
}
</script>
